import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import FeaturedCard from "../../zzz/molecules/mainNavigation/blogMenu/FeaturedCard"
import { path, getFluid } from "../../lib/util"
import Drawer from "../../zzz/molecules/drawer"
import TeamDrawerContent from "../../zzz/molecules/drawer/TeamDrawerContent"

const sortByTitle = (a, b) => {
  if (a?.title < b?.title) {
    return -1
  }
  if (a?.title > b?.title) {
    return 1
  }
  return 0
}

const OurStoryPage = ({ location }) => {
  const data = useStaticQuery(PAGE)

  const members_unsorted = data.members.nodes
  const page = data.page
  const seo = data.page.seo

  const [drawOpen, setDrawOpen] = useState(false)
  const [active, setActive] = useState(2)

  const closeDrawer = () => setDrawOpen(false)

  const members = []
  const members_order_map = {}
  const members_with_empty_order = []
  for (let i = 0; i < members_unsorted.length; ++i) {
    const member = members_unsorted[i]
    const order = member?.acf_team_members?.teamMemberOrder
    if (!order) {
      members_with_empty_order.push(member)
    } else {
      if (!(order in members_order_map)) {
        members_order_map[order] = []
      }
      members_order_map[order].push(member)
    }
  }

  for (const order in members_order_map) {
    const group = members_order_map[order]
    const sorted = group.sort(sortByTitle)
    members.push(...sorted)
  }
  members.push(...members_with_empty_order.sort(sortByTitle))

  return (
    <Layout location={location}>
      <Drawer open={drawOpen} closeFn={closeDrawer}>
        <TeamDrawerContent
          fluid={getFluid(members[active])}
          closeFn={closeDrawer}
          title={getTitle(members[active])}
          position={getPosition(members[active])}
          description={members[active].content}
        />
      </Drawer>
      <SEO title="Contact Us" location={location} post_id={460} seo={seo} />
      <Heading
        subTitle={page.aboutUs.aboutUsSubTitle}
        fluid={getFluid(page)}
        location={location}
      />
      <Wrap>
        <Inner>
          <TitleBox>
            <h2>{page.title}</h2>
            <p>{page.aboutUs.aboutUsSubTitle}</p>
          </TitleBox>
          <Grid>
            {members.map((node, i) => (
              <FeaturedCard
                key={i}
                onClick={() => {
                  setDrawOpen(!drawOpen)
                  setActive(i)
                }}
                noLink
                title={getTitle(node)}
                subTitle={getPosition(node)}
                fluid={getFluid(node)}
              />
            ))}
          </Grid>
        </Inner>
      </Wrap>
    </Layout>
  )
}

// ================
// 		HELPERS
// ================

const getPosition = (node) => path(["position", "position"], node)

const getTitle = (node) => path(["title"], node)

// ================
// 		STYLES
// ================

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  h2,
  p {
    text-align: center;
    color: #223247;
    margin-top: 10px;
  }
  h2 {
    border-bottom: 3px solid red;
  }
`

const Wrap = styled.div`
  background: #f6f6f6;
  padding: 50px 0;
`

// ================
// 		QUERY
// ================

const PAGE = graphql`
  {
    page: localWpGraphQlPages(pageId: { eq: 460 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      aboutUs {
        aboutUsSubTitle
      }
      featuredImage {
        imageFile {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    members: allLocalWpGraphQlMembers {
      nodes {
        title
        content
        position {
          position
        }
        acf_team_members {
          teamMemberOrder
        }
        featuredImage {
          imageFile {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default OurStoryPage
