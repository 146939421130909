import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { BtnSquare } from "../../atoms/buttons"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowLeft from "../../atoms/icons/files/arrow-left.jsx"

// This feature seems unused by the client

// import Facebook from "../../atoms/icons/files/social-facebook.jsx"
// import Twitter from "../../atoms/icons/files/social-twitter.jsx"
// import Instagram from "../../atoms/icons/files/social-instagram.jsx"
// import LinkedIn from "../../atoms/icons/files/social-linkedin.jsx"

// const socialIcons = {
//   facebook: Facebook,
//   twitter: Twitter,
//   instagram: Instagram,
//   linkedin: LinkedIn,
// }

const TeamDrawerContent = ({
  fluid,
  closeFn,
  title,
  position,
  description,
}) => {
  return (
    <Container>
      <StyledBackground
        style={{ backgroundPosition: "center top" }}
        fluid={fluid}
      >
        <Overlay>
          <BackArrow onClick={closeFn}>
            <SvgIcon
              size="md"
              hue="danger"
              shade="fontColor"
              SvgComponent={ArrowLeft}
            />
          </BackArrow>
          <TextWrap>
            <div className="text">
              <h2>{title}</h2>
              <p>{position}</p>
            </div>
            {/* This feature seems unused by the client */}
            {/* <div className="icons">
              {social &&
                social.map((link, i) => (
                  <a href={link.link} key={i}>
                    <SvgIcon
                      size="md"
                      hue="danger"
                      shade="fontColor"
                      SvgComponent={socialIcons[link.title]}
                    />
                  </a>
                ))}
            </div> */}
          </TextWrap>
        </Overlay>
      </StyledBackground>
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <BtnSquare size="lg" asButton onClick={closeFn}>
          <SvgIcon
            size="md"
            hue="danger"
            shade="fontColor"
            SvgComponent={ArrowLeft}
          />{" "}
          CLOSE
        </BtnSquare>
      </div>
    </Container>
  )
}

const StyledBackground = styled(BackgroundImage)``

const Container = styled.div`
  .content {
    padding: 20px 20px 100px 20px;
    height: 60vh;
    overflow-y: scroll;
    button {
      margin-top: 20px;
    }
  }
`

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  .text {
    h2 {
      font-size: 30px;
      color: white;
    }
    p {
      color: #099fdc;
      font-size: 18px;
    }
  }
  .icons {
  }
`

const Overlay = styled.div`
  height: 40vh;
  background: linear-gradient(
    180.54deg,
    rgba(35, 63, 101, 0.14) 55%,
    rgba(34, 50, 71, 0.62) 100%
  );
  padding: 20px;
  position: relative;
  display: flex;
  align-items: flex-end;
`

const BackArrow = styled.div`
  background: #223247;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 7px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(-2px);
  }
`

export default TeamDrawerContent
